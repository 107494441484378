import React from "react";
import routes from "admin/meta_routes";
import _ from "lodash";
import {t} from "i18n";

const navItems = [
  {
    label: t("Dashboard"),
    icon: "fa fa-area-chart",
    to: `${routes.home}`,
  },
  {
    label: t("Users"),
    icon: "fa fa-user",
    to: `${routes.users}`,
    permission: "user",
    content: [
      {
        label: t("User"),
        to: `${routes.users}`,
        permission: "user.view",
      },
      {
        label: t("Add"),
        to: `${routes.usersAdd}`,
        permission: "user.add",
      },
    ],
  },
  {
    label: t("Roles"),
    icon: "fa fa-cubes",
    to: `${routes.roles}`,
    permission: "role",
    content: [
      {
        label: t("Role"),
        to: `${routes.roles}`,
        permission: "role.view",
      },
      {
        label: t("Add"),
        to: `${routes.rolesAdd}`,
        permission: "role.add",
      },
    ],
  },
  {
    label: t("Birds"),
    icon: "fas fa-feather",
    to: `${routes.birds}`,
    permission: "bird",
    content: [
      {
        label: t("Bird List"),
        to: `${routes.birds}`,
        permission: "bird.view",
      },
      {
        label: t("Add Bird"),
        to: `${routes.birdAdd}`,
        permission: "bird.add",
      },
    ],
  },
  {
    label: t("Checklists"),
    icon: "fa fa-check",
    to: `${routes.checklists}`,
    permission: "checklist",
  },

  {
    label: t("Checklist Mapping"),
    icon: "fa fa-map-marker",
    to: `${routes.checklistMap}`,
    permission: "checklist.view",
  },
  {
    label: t("About Us"),
    icon: "fa fa-info",
    to: `${routes.aboutUs}`,
    permission: "other.aboutUs",
  },
  {
    label: t("Create Reports"),
    icon: "fa fa-chart-pie",
    to: `${routes.createReports}`,
    permission: "isAdmin",
  },
  {
    label: t("File Dump"),
    icon: "fa fa-file",
    to: `${routes.fileDump}`,
    permission: "isAdmin",
  },
];

const checkPermission = (item, permissions) => {
  if (permissions.isAdmin) return true;
  if (item.permission) {
    return _.get(permissions, item.permission);
  }
  return true;
};

export const generateNavItems = (permissions) => {
  const items = [];
  navItems.forEach((item) => {
    if (item.content) {
      item.content = item.content.filter((i) => {
        if (checkPermission(i, permissions)) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    if (checkPermission(item, permissions)) {
      items.push(item);
    }
  });
  return items;
};
